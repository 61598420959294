import React from 'react';
// import '../sass/styles.scss';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image'
import YoutubeCarousel from './YoutubeCarousel';
import ImageCarousel from './ImageCarousel';
import Story from './Story';
import Characters from './Characters';
// import './RolandsQuest.scss';
import './SubscribeModal.scss';
import Wolf from '../img/spritesheets/Wolf_bowman.png';
import ScreenShot1 from '../img/screenshots/popup/screenshot_2.jpg';
import ScreenShot2 from '../img/screenshots/popup/mortimer.gif';
import ScreenShot3 from '../img/screenshots/popup/screenshot_5.jpg';
import ScreenShot4 from '../img/screenshots/popup/screenshot_4.jpg';
import ScreenShot5 from '../img/screenshots/popup/screenshot_1.jpg';
import ScreenShot6 from '../img/screenshots/popup/screenshot_6.jpg';

class RolandsQuest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {email: ""};
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    // componentDidMount() {
  
    //     // Changing the state after 2 sec
    //     // from the time when the component
    //     // is rendered
    //     // setTimeout(() => {
    //     //   this.setState({ color: 'wheat' });
    //     // }, 2000);
    //     // const myCarouselElement = document.querySelector('#carouselAutoplaying')

    //     // const carousel = new bootstrap.Carousel(myCarouselElement, {
    //     // interval: 2000,
    //     // touch: false
    //     // });
    //     //const carousel = new bootstrap.Carousel('#carouselAutoplaying');
    //   }

    submitEmail = async () => {
        await fetch('/api/addemail', {
            method: 'POST',
            body: JSON.stringify({email: this.state.email}),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
        .catch((err) => {
            console.log(err.message);
        });
        /*await fetch(process.env.REACT_APP_EMAILAPI + 'email/AddUser', {
            method: 'POST',
            body: JSON.stringify({email: this.state.email}),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        })
        .catch((err) => {
            console.log(err.message);
        });*/
    };

    handleSubmit = async (event) => {
        event.preventDefault();
        // alert('Thank you!');
        await this.submitEmail();
    }

    handleEmailChange(event) {
        this.setState({email: event.target.value});
    }

    render() {
        const videos = ["https://www.youtube.com/embed/cEix7hMfgQI", "https://www.youtube.com/embed/K3422HmHwAc", "https://www.youtube.com/embed/ndhLuSWimks",
            "https://www.youtube.com/embed/gWrql6FHFiY"];
        const screenshots = [ScreenShot1, ScreenShot2, ScreenShot3, ScreenShot4, ScreenShot5, ScreenShot6];
      return (
        <div>


{/* <div class="nav-btn _overlay"></div> */}
{/* <main class="content"> */}
    <Card className="_title">
    </Card>
    <Card bg='black' text='white' className="card _calltoaction">
        <Card.Body>
            <Card.Title>Roland's Quest is an Action RPG where the player can craft in the environment and create fun stuff.</Card.Title>
        </Card.Body>
        <Card.Text>
            <Form className="contact-form" name="contact_us" onSubmit={this.handleSubmit}>
                <Form.Control type="email" onChange={this.handleEmailChange} placeholder='Email Address' id="form-email-top2" />
                {/* <input type="email" class="form__input formemail" tabindex="2" name="email" value="" placeholder="Email Address" required aria-required="true" /> */}
                <Button className="submitButton" variant="primary" type="submit">Subscribe to Newsletter</Button>
                {/* <div class="submit_container">
                    <input type="submit" class="btn _contact _title submitbutton" value="Subscribe to Newsletter" />
                </div> */}
                <div class="form-row">
                    <span class="member__name resultspan"></span>
                </div>
            </Form>
        </Card.Text>
    </Card>
    <Card bg='black' class="card _video">
        <YoutubeCarousel Items={videos} />
    </Card>

    <div class="subscribeModal shaded smallborderraduis" id="subscribeModal">
        <p class="padding-below">Roland's Quest is an Action RPG where the player can craft in the environment and create fun stuff.</p>
        <Form onSubmit={this.handleSubmit}>
        {/* <form class="contact-form" name="contact_us" action="#" method="post" id="contact-form-top"> */}
            <Form.Control type="email" onChange={this.handleEmailChange} placeholder='Email Address' id="form-email-top" />
            {/* <input type="email" class="form__input formemail" tabindex="2" name="email" value="" placeholder="Email Address" id="form-email-top" required aria-required="true" /> */}
            {/* <div class="submit_container"> */}
                <Button class="submitButton" variant="primary" type="submit">Subscribe to Newsletter</Button>
                {/* <input type="submit" class="btn _contact _title submitbutton" value="Subscribe to Newsletter" /> */}
            {/* </div> */}
            <div class="form-row">
                <span id="Result-top" class="member__name resultspan"></span>
            </div>
        </Form>
        {/* </form> */}
    </div>

    <Card bg='dark' text='white' class="card _about" id="about">
        <Card.Body>
            <Card.Title><h1>What makes our game different</h1></Card.Title>
            <Card.Text>
                <div class="about">
                    <Image src={Wolf} width="164" height="159" className="float-right" />
                    {/* <img src={Wolf} width="164" height="159" class="float-right" /> */}
                    <p>Roland's Quest's dungeons have two major elements: the hammer, and its conduits. A conduit transforms the hammer into a tool to match the environment.<br /><br />Sometimes your hammer is a flamethrower; sometimes it's a music box; it may redirect electricity or let you perform alchemy. This allows for one-of-a-kind dungeons that keep you on your toes and let you get creative.</p>
                </div>
                <div class="float-none"></div>
                <div class="hidden">
                    <div class="shaded">
                        <div class="shadedwindow">
                            <p>Crowd control with Disperse Helix</p>
                            <img alt="disperse helix" src="~/img/Disperse-helix.gif" />
                        </div>
                        <div class="shadedwindow">
                            <p>Team up with Lulu to create fiery devastation</p>
                            <img alt="lulu" src="~/img/molten-carousel.gif" />
                        </div>
                        <div class = "clear"></div>
                    </div>
                </div>
                <ImageCarousel Items={screenshots} />
            </Card.Text>
        </Card.Body>
        
    </Card>

    <Story />
    <Characters />

    <Card className="_contact" id="contact">
        <Card.Body>
            <Card.Title>
                <h1>Subscribe to our Newsletter</h1>
            </Card.Title>
            <Card.Text>
                <script async src="//platform.twitter.com/widgets.js" charset="utf-8"></script>
                <div class="_updateFrame">
                    <div class="about__descr">
                        <p>Fill out the form below to subscribe to our newsletter and get monthly updates about Roland's Quest.</p>
                    </div>

                    <Form className="contact-form" name="contact_us" onSubmit={this.handleSubmit} id="contact-form">
                        <div class="form-row">
                            <Form.Control type="email" onChange={this.handleEmailChange} placeholder='Email Address' id="form-email-top3" />
                            {/* <input type="email" class="form__input formemail" tabindex="2" name="email" value="" placeholder="Email" id="form-email" required /> */}
                        </div>
                        <div class="submit_container">
                            <Button className="submitButton" variant="primary" type="submit">Subscribe to Newsletter</Button>
                            {/* <input type="submit" class="btn _contact _title submitbutton" value="Subscribe to Newsletter" /> */}
                        </div>
                        <div class="form-row">
                            <span id="Result" class="member__name resultspan"></span>
                        </div>
                    </Form>
                </div>
            </Card.Text>
        </Card.Body>

    </Card>

    <footer class="footer">
    <div class="footer-inner">
        <p class="copyright">© 2018 Colony Games Interactive. All rights reserved.</p>
        {/* <div class="socials">
            <a href="http://www.discord.gg/4sZJCjT " class="social-link" target="_blank"><img src="http://rolansquest.azureedge.net/img/discord-logo-white.png" alt="Discord" class="link__img"></a>
            <a href="https://twitter.com/ColonySoftware" class="social-link" target="_blank"><img src="http://rolansquest.azureedge.net/img/icon_twitter.png" alt="Twitter" class="link__img"></a>
            <a href="https://www.facebook.com/ColonyGames" class="social-link" target="_blank"><img src="http://rolansquest.azureedge.net/img/icon_facebook.png" alt="Facebook" class="link__img"></a>
            <a href="https://www.instagram.com/colonygames" class="social-link target=" _blank"><img src="http://rolansquest.azureedge.net/img/icon_instagram.png" alt="Instagram" class="link__img"></a>
        </div> */}
    </div>
    </footer>
    {/* 


    

    <section class="card _artsound" id="artsound">
        <h2 class="card-title">Meet the team</h2>
        <div class="team">
            <div class="team-member">
                <img src="http://rolansquest.azureedge.net/img/team/Pixel-portraits_Brian.gif" alt="Brian Karcher" class="member__img" />
                <div class="member-info">
                    <span class="member__name">Brian Karcher</span><br />
                    <span class="member__name">Coder</span><br />
                    <span class="member__name"><a class="yellow" href="https://twitter.com/_BrianKarcher" target="_blank">Brian Karcher</a></span>
                </div>
            </div>
            <div class="team-member">
                <img src="http://rolansquest.azureedge.net/img/team/Pixel-portraits_Eph.gif" alt="Ephraim Mangohig" class="member__img" />
                <div class="member-info">
                    <span class="member__name">Ephraim Mangohig</span><br />
                    <span class="member__name">Level Designer</span>
                </div>
            </div>
            <div class="team-member">
                <img src="http://rolansquest.azureedge.net/img/team/Pixel-portraits_Kev.gif" alt="Kevin Tan" class="member__img" />
                <div class="member-info">
                    <span class="member__name">Kevin Tan</span><br />
                    <span class="member__name">Art Director</span>
                </div>
            </div>
            <div class="team-member">
                <img src="http://rolansquest.azureedge.net/img/team/Pixel-portraits_Steve.gif" alt="Steve Syz" class="member__img" />
                <div class="member-info">
                    <span class="member__name">Steve Syz</span><br />
                    <span class="member__name">Composer</span><br />
                    <span class="member__name"><a class="yellow" href="https://twitter.com/SteveSyzMusic" target="_blank">Steve Syz Music</a></span>
                </div>
            </div>
        </div>
    </section>



     */}
{/* </main> */}



        </div>
      );
    }
  }

export default RolandsQuest;