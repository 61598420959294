import React from 'react';
import Card from 'react-bootstrap/Card';
import Image from 'react-bootstrap/Image'
import Angelic from '../img/angelic.gif'

function Story(props) {
    return (
        <Card bg="black" text="white" className="bottom-buffer about">
            <Card.Body>
                <Card.Title><h1>Story</h1></Card.Title>
                <Card.Text>
                    {/* <div class="about__img"></div> */}
                    <Image src={Angelic} className='float-left angelic' />
                    <p class="about__descr">
                        For Roland, the world ends at the town's edge. Even the politics of the capitol are a far-off dream for the small town lad. He has few worries, until a mysterious band of soldiers is spotted in the area. Marching in unknown colors, no one seems to know where these elusive outsiders come from. Moreover, no one seems to care. <br /><br />
                        When they should be banding together, kingdoms are too absorbed with their petty feuds to get involved. Roland is faced with a choice: stay in the dark, or leave his quiet life behind. <br /><br /><br />
                        With his hard-hitting sidekick Lulu, Roland must unravel the secrets of an evil Lord, cross into exciting new territories and uncover his world's dark, long forgotten past. <br /><br />
                        ...In a world teeming with evil powers, is destruction inevitable?
                    </p>
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

export default Story;