import React from 'react';
import Card from 'react-bootstrap/Card';
import Roland from '../img/chars/Rolan_Website.png';
import RolandMini from '../img/chars/rolan_mini.png';
import Lulu from '../img/chars/Lulu_Website.png';
import LuluMini from '../img/chars/lulu_mini.png';
import Aksel from '../img/chars/Aksel_Website.png';
import AkselMini from '../img/chars/aksel_mini.png';
import DarkLord from '../img/chars/DarkLord_Website.png';
import DarkLordMini from '../img/chars/lucius_mini.png';
// import './Characters.scss';

function Characters(props) {
    return (
        <Card bg='dark' text='white' class="card _characters" id="characters">
            <Card.Body>
                <Card.Title><h1>Characters</h1></Card.Title>
                <Card.Text>
                    <div class="chars">
                        <div class="char">
                            <img src={Roland} alt="Roland" class="char__img _roland" />
                            <div class="char-descr">
                                <img src={RolandMini} alt="pixel Roland" class="char__mini _roland" />
                                <h3 class="char__who"><strong class="char__name">Roland</strong><span class="char__type"> - The hero</span></h3>
                                <p class="char__about">A smith from the unusually small Forest Town. Raised by his grandfather, he excels at hunting, lounging, and cruising through life. Resourceful, kind, and all around “nice guy,” he's a big fish in a tiny pond. It's time for something greater. It's time for Roland to learn what he wants, needs and, most importantly, that everyone's destiny is important.</p>
                            </div>
                        </div>
                        <div class="char">
                            <img src={Lulu} alt="Lulu" class="char__img _lulu" />
                            <div class="char-descr">
                                <img src={LuluMini} alt="pixel Lulu" class="char__mini _lulu" />
                                <h3 class="char__who"><strong class="char__name">Lulu</strong><span class="char__type"> - The mystery</span></h3>
                                <p class="char__about">The smart, and positively devious right-hand lady. Lulu is driven almost by madness to succeed, yet remains wary to share her story. After haphazardly joining forces with Roland, she wanders between cold mercenary and true friend. It seems even she doesn't know what she's capable of.</p>
                            </div>
                        </div>
                        <div class="char">
                            <img src={Aksel} alt="Aksel" class="char__img _aksel" />
                            <div class="char-descr">
                                <img src={AkselMini} alt="pixel Aksel" class="char__mini _aksel" />
                                <h3 class="char__who"><strong class="char__name">Aksel</strong><span class="char__type"> - The rival</span></h3>
                                <p class="char__about">Hailing from the powerful and prideful capitol of the North, Aksel is the youngest from an ancient family of warriors. Above all else, she wants what is best for her kingdom. Charismatic and bloodthirsty, she intends to lead her people into a new world, where their status and traditions are respected. Aksel is your Gary Oak, and you just can't shake her. Her nuanced personality and dangerous patriotism make her an absolutely unforgettable character.</p>
                            </div>
                        </div>
                        <div class="char">
                            <img src={DarkLord} alt="Lucius" class="char__img _lucius" />
                            <div class="char-descr">
                                <img src={DarkLordMini} alt="pixel Lucius" class="char__mini _lucius" />
                                <h3 class="char__who"><strong class="char__name">Lucius</strong><span class="char__type"> - The tyrant</span></h3>
                                <p class="char__about">Haven't you heard? There are rumors the mysterious Lord comes from a long line of power-hungry, highly intelligent aristocrats. Sangria may be an intellectual, but he is also extremely narcissistic, with a perverted black-and-white view of the world. Of course, the evil Lord's plan is anything but what it seems. Only by unraveling his antagonist's complex story can Roland find the purpose he needs to save the world.</p>
                            </div>
                        </div>
                    </div>
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

export default Characters;