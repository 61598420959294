import React from 'react';
import Carousel from 'react-bootstrap/Carousel';
import'./YoutubeCarousel.scss';

function YoutubeCarousel(props) {
    return (
        <Carousel>
            {props.Items.map((item) => (
            <Carousel.Item>
            <iframe class="d-block w-100 h-100" title="myCarosoul" src={item + "?iv_load_policy=3?&rel=0&showinfo=0&modestbranding=0&autohide=1"} frameborder="0" allowfullscreen></iframe>
                {/* <Carousel.Caption>
                <h3>First slide label</h3>
                <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                </Carousel.Caption> */}
            </Carousel.Item>
            ))}
        </Carousel>
        // <button className="square" onClick={props.onClick}>
        //     {props.value}
        // </button>
    )
}

export default YoutubeCarousel;